<template>
	<main>
		<div
			v-if="fetchingBanners"
			class="d-flex justify-content-center align-items-center"
			>
			<div class="spinner-border" role="status"></div>
		</div>
		<div v-else>
			<b-card no-body>
				<b-table
					caption-top
					show-empty
					striped
					selectable
					hover
					responsive
					:items="banners"
					:fields="fields"
					:current-page="currentPage"
					:busy="fetchingBanners"
					head-variant="null"
					@row-clicked="viewBanner"
					>
					<template #table-caption>
						<div class="px-3 d-flex justify-content-between align-items-center">
							<p style="font-size: 16px; font-weight: bold">
								Promotional banner list
							</p>
							<p
								v-if="banners.length"
								style="color: red; cursor: pointer"
								@click="handleBannersDelete"
								>
								<span
									style="color: red; font-size: 20px"
									class="fa fa-trash-o"
									></span>
								Clear all
							</p>
						</div>
					</template>
					<template #table-busy>
						<div
							class="d-flex flex-column justify-content-center align-items-center"
							>
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>

					<template #empty>
						<p class="text-center text-secondary">No records available</p>
					</template>

					<template #cell(date)="data">
						{{ data.item.date }}
					</template>
					<template #cell(title)="data">
						{{ data.item.title }}
					</template>
					<template #cell(enable)="data">
						<label class="switch">
							<input
								v-model="data.item.status"
								@change="toggleStory(data.item)"
								type="checkbox"
								/>
							<span class="slider round"></span>
						</label>
					</template>
					<template #cell(actions)="data">
						<div class="">
							<b-dropdown
								size="sm"
								left
								variant="link"
								class="position-relative text-center"
								toggle-class="text-decoration-none"
								no-caret
								no-flip
								>
								<template #button-content>
									<div class="w-100 text-center">
										<i class="fe fe-more-vertical"></i>
									</div>
								</template>
								<b-dropdown-item
									@click.prevent.stop="updateModal(data.item, 'embedLink')"
									>
									Enable link
								</b-dropdown-item>
								<b-dropdown-item
									@click.prevent.stop="updateModal(data.item, 'linkCarousel')"
									>
									Link to carousel
								</b-dropdown-item>
								<b-dropdown-item
									@click.prevent.stop="updateModal(data.item, 'linkRoute')"
									>
									Link to route
								</b-dropdown-item>
								<b-dropdown-item
									@click.prevent.stop="updateDiscoveryModal(data.item)"
									>
									Link to discovery page
								</b-dropdown-item>
							</b-dropdown>
						</div>
					</template>
				</b-table>
			</b-card>

			<div class="card-footer" v-if="totalRecords">
				<b-pagination
					v-model="currentPage"
					:total-rows="totalRecords"
					:per-page="perPage"
					></b-pagination>
			</div>
		</div>

		<b-modal
			no-close-on-esc
			no-close-on-backdrop
			id="bannerUpdateModal"
			hide-footer
			centered
			no-stacking
			>
			<template #modal-title>
				<h3 style="color: #444854">
					{{
						selectedModalItem === 'embedLink'
							? 'Embed Link'
							: selectedModalItem === 'linkCarousel'
								? 'Link Carousel'
								: selectedModalItem === 'linkRoute'
									? 'Link Route'
									: ''
					}}
				</h3>
			</template>

			<div class="form-group ctaUrlParent">
				<label style="color: #444854" for="CTAUrl" class="form-label">{{
					selectedModalItem === 'embedLink'
						? 'Link'
						: selectedModalItem === 'linkCarousel'
							? 'Select carousel'
							: selectedModalItem === 'linkRoute'
								? 'Select route'
								: ''
				}}</label>

				<div v-if="selectedModalItem === 'embedLink'">
					<input
						id="CTAUrl"
						name="CTAUrl"
						placeholder="Enter link"
						required
						type="url"
						v-model="selectedRow.action_value"
						class="form-control"
						/>
					<img src="@/assets/img/linkIcon.svg" class="ctaUrlChild" alt="" />
				</div>

				<div v-if="selectedModalItem === 'linkCarousel'">
					<v-select
						v-model="selectedCarousel"
						label="name"
						class="form-group"
						:options="carouselsList"
						:reduce="(carousel) => carousel.id"
						>
					</v-select>
				</div>

				<div v-if="selectedModalItem === 'linkRoute'">
					<v-select
						v-model="selectedRoute"
						class="form-group"
						placeholder="Search"
						:reduce="(route) => route.id"
						:options="routes"
						label="route_code"
						required
						>
						<template v-slot:option="route">
							<span>{{
								`${route.route_code ? route.route_code + ' - ' : ''} From ${
									route.pickup
								} to ${route.destination}`
							}}</span>
						</template>
						<template v-slot:selected-option="route">
							<span>{{
								`${route.route_code ? route.route_code + ' - ' : ''} From ${
									route.pickup
								} to ${route.destination}`
							}}</span>
						</template>
					</v-select>
				</div>
			</div>

			<div class="d-flex justify-content-between align-items-center mt-5">
				<button
					:disabled="!isValid"
					@click="handleBannerUpdate(selectedModalItem, selectedRow)"
					type="submit"
					class="btn btn-primary w-100"
					>
					{{ selectedModalItem === 'embedLink' ? 'Embed' : 'Link' }}
				</button>
			</div>
		</b-modal>

		<b-modal
			no-close-on-esc
			no-close-on-backdrop
			id="bannerDiscoveryModal"
			hide-header-close
			hide-footer
			hide-header
			centered
			no-stacking
			>
			<div
				class="d-flex justify-content-between align-items-center flex-column"
				>
				<img src="@/assets/img/warning.svg" alt="" />
				<h2 class="pt-4">Link discovery page</h2>
				<p class="pb-0 mb-0" style="font-weight: lighter">
					Are you sure you would like to link this banner to
				</p>
				<p class="pt-0 mt-0" style="font-weight: lighter">
					the discovery page ?
				</p>
			</div>

			<div class="d-flex justify-content-between align-items-center mt-5">
				<button
					@click="$bvModal.hide('bannerDiscoveryModal')"
					type="submit"
					class="btn border-primary w-100 mr-3"
					>
					Cancel
				</button>
				<button
					@click="ContinueBannerDiscoveryLinking"
					type="submit"
					class="btn btn-primary w-100 ml-3"
					>
					Proceed
				</button>
			</div>
		</b-modal>

		<b-modal
			no-close-on-esc
			no-close-on-backdrop
			id="updateSuccessModal"
			hide-header-close
			hide-footer
			hide-header
			centered
			no-stacking
			>
			<div
				class="d-flex justify-content-between align-items-center flex-column"
				>
				<img src="@/assets/img/successIcon.svg" alt="" />
				<h2 class="pt-4">
					{{
						successModal === 'embedLinkSuccess'
							? 'Link Embeded'
							: successModal === 'linkCarouselSuccess'
								? 'Carousel Linked'
								: successModal === 'linkRouteSuccess'
									? 'Route Linked'
									: ''
					}}
				</h2>
				<p class="pb-0 mb-0" style="font-weight: lighter">
					You have successfully linked this banner to
				</p>
				<p class="pt-0 mt-0" style="font-weight: lighter">
					a
					{{
						successModal === 'embedLinkSuccess'
							? 'webpage'
							: successModal === 'linkCarouselSuccess'
								? 'carousel'
								: successModal === 'linkRouteSuccess'
									? 'route'
									: ''
					}}
				</p>
			</div>

			<button
				@click="$bvModal.hide('updateSuccessModal')"
				type="submit"
				class="btn btn-primary w-100 mt-5"
				>
				Dismiss
			</button>
		</b-modal>

		<b-modal
			no-close-on-esc
			no-close-on-backdrop
			id="discoveryPageLinkingSuccessModal"
			hide-header-close
			hide-footer
			hide-header
			centered
			no-stacking
			>
			<div
				class="d-flex justify-content-between align-items-center flex-column"
				>
				<img src="@/assets/img/successIcon.svg" alt="" />
				<h2 class="pt-4">Discovery page linked</h2>
				<p class="pb-0 mb-0" style="font-weight: lighter">
					You have successfully linked this banner to
				</p>
				<p class="pt-0 mt-0" style="font-weight: lighter">
					the discovery page.
				</p>
			</div>

			<button
				@click="$bvModal.hide('discoveryPageLinkingSuccessModal')"
				type="submit"
				class="btn btn-primary w-100 mt-5"
				>
				Dismiss
			</button>
		</b-modal>
	</main>
</template>

<script>
import Swal from 'sweetalert2'
import moment from 'moment'
import routeResource from '@/api/route'
export default {
  data () {
    return {
      fields: [
        {
          key: 'date',
          label: 'Date'
        },
        {
          key: 'title',
          label: 'Banner Title'
        },
        {
          key: 'enable',
          label: 'Enable'
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
      perPage: 20,
      selectedRoute: null,
      currentPage: 1,
      totalRecords: 0,
      fetchingBanners: false,
      fetchingCarousels: false,
      selectedModalItem: '',
      deletingBanner: false,
      routes: [],
      carouselsList: [],
      banners: [],
      selectedRow: {},
      selectedCarousel: null,
      successModal: '',
      discoveryData: {}
    }
  },
  created () {
    this.loadBanners()
    this.loadCarousels()
    this.fetchRoutes()
  },
  methods: {
    handleBannerUpdate (selectedModal, data) {
      if (
        this.selectedRoute === '' ||
        this.selectedRow.action_value === '' ||
        this.selectedCarousel === ''
      ) {
        return
      }
      const patchObject = {}

      if (selectedModal === 'linkRoute') {
        patchObject.action_type = 'open_route'
        patchObject.action_value = String(this.selectedRoute)
      }

      if (selectedModal === 'embedLink') {
        patchObject.action_type = 'open_link'
        patchObject.action_value = String(this.selectedRow.action_value)
      }

      if (selectedModal === 'linkCarousel') {
        patchObject.action_type = 'open_carousel'
        patchObject.action_value = String(this.selectedCarousel)
      }

      this.axios
        .patch(`/v1/dynamic-dashboard/banners/${data.id}`, patchObject)
        .then(() => {
          this.$bvModal.show('updateSuccessModal')
          if (selectedModal === 'embedLink') {
            this.successModal = 'embedLinkSuccess'
            this.selectedRow.action_value = ''
          }

          if (selectedModal === 'linkCarousel') {
            this.successModal = 'linkCarouselSuccess'
            this.selectedCarousel = ''
          }

          if (selectedModal === 'linkRoute') {
            this.successModal = 'linkRouteSuccess'
            this.selectedRoute = ''
          }
        })
        .catch((err) => {

        })
    },
    updateModal (data, selectedModal) {
      this.selectedRow = data
      this.selectedModalItem = selectedModal
      this.$bvModal.show('bannerUpdateModal')
    },
    updateDiscoveryModal (data) {
      this.discoveryData = data
      this.$bvModal.show('bannerDiscoveryModal')
    },
    fetchRoutes () {
      // TODO: raise issue with backend engineer on field added except by specifying limit
      routeResource
        .listRoute({
          params: { status: 1, limit: 1000000 }
        })
        .then((res) => {
          this.routes = res
        })
        .catch(() => {})
    },
    viewBanner (banner) {
      this.$router.push({ name: 'ShowBanner', params: { bannerId: banner.id } })
    },
    ContinueBannerDiscoveryLinking () {
      this.axios
        .patch(`/v1/dynamic-dashboard/banners/${this.discoveryData.id}`, {
          action_type: 'open_route_discovery'
        })
        .then(() => {
          this.$bvModal.show('discoveryPageLinkingSuccessModal')
        })
        .catch((error) => {

        })
    },
    async handleBannersDelete () {
      const deleteStatus = this.banners.every((eachBaner) => {
        return eachBaner.status === false
      })

      if (!deleteStatus) {
        Swal.fire(
          'OOPS!',
          'Sorry you cannot delete all banners while some are still active. Ensure you deactivate all banners before deleting all.',
          'info'
        )
        return
      }

      this.deletingBanner = true
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to delete all banners?',
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .delete('/v1/dynamic-dashboard/banners')
            .then(() => {
              this.deletingBanner = false
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occurred, please try again.'

              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadBanners()
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Banners have been successfully deleted',
            showCloseButton: true
          })
        }
      })
    },
    loadBanners () {
      this.fetchingBanners = true
      this.axios
        .get('/v1/dynamic-dashboard/banners')
        .then((res) => {
          this.banners = this.transformedBannerArray(res.data.data)
          this.totalRecords = res.data.metadata.total
        })
        .catch(() => {
          this.$toastr.e('Something went wrong while fetching banners', 'Error')
        })
        .finally(() => {
          this.fetchingBanners = false
        })
    },
    loadCarousels () {
      this.fetchingCarousels = true
      this.axios
        .get('/v1/dynamic-dashboard/carousels')
        .then((res) => {
          this.carouselsList = res?.data
        })
        .catch(() => {
          this.$toastr.e(
            'Something went wrong while fetching carrousels',
            'Error'
          )
        })
        .finally(() => {
          this.fetchingCarousels = false
        })
    },
    transformedBannerArray (payload) {
      const newBannerData = []
      payload.forEach((eachBannerObject) => {
        newBannerData.push({
          id: eachBannerObject.id,
          title: eachBannerObject.title,
          date: moment(eachBannerObject.created_at).format('YYYY-MM-DD'),
          status: eachBannerObject.status === 'active',
          action_type: eachBannerObject.action_type,
          action_value: eachBannerObject.action_value,
          image_url: eachBannerObject.image_url,
          location: eachBannerObject.location
        })
      })
      return newBannerData
    },
    toggleStory (item) {
      if (item.status === true) {
        this.$swal({
          title: 'Enable Banner',
          html: `<p class="pb-0 mb-0" style="font-weight: lighter">Are you sure you would like to make ${item.title} banner visible to all users ?</p>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Proceed!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.handleSubscription(item, result.isConfirmed)
              .then(() => {
                if (item.status === true) {
                  this.$toastr.s(
                    `${item.title} banner visibility has been turned on`,
                    'Success'
                  )
                }

                if (item.status === false) {
                  this.$toastr.s(
                    `${item.title} banner visibility has been turned off`,
                    'Success'
                  )
                }
              })
              .catch((error) => {
                if (error.message === 'Banner Turn On Error') {
                  item.status = false
                }

                if (error.message === 'Banner Turn Off Error') {
                  item.status = true
                }
              })
          } else {
            item.status = false
          }
        })
      }

      if (item.status === false) {
        this.$swal({
          title: 'Disable Banner',
          html: `<p class="pb-0 mb-0" style="font-weight: lighter">Are you sure you would like to make this banner</p>
                 <p class="pt-0 mt-0" style="font-weight: lighter">not visible to all users ?</p>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Proceed!'
        }).then((result) => {
          if (result.isConfirmed) {
            result.isConfirmed = false
            this.handleSubscription(item, result.isConfirmed)
              .then(() => {
                if (item.status === false) {
                  this.$toastr.s(
                    `${item.title} banner visibility has been turned off`,
                    'Success'
                  )
                }
              })
              .catch((error) => {
                if (error.message === 'Banner Turn On Error') {
                  item.status = false
                }

                if (error.message === 'Banner Turn Off Error') {
                  item.status = true
                }
              })
          } else {
            item.status = 'active'
          }
        })
      }
    },

    async handleSubscription (item, value) {
      try {
        await this.axios.patch(`/v1/dynamic-dashboard/banners/${item.id}`, {
          status: value === true ? 'active' : 'inactive'
        })

        if (value) {
          this.$swal(
            'Success!',
            `You have successfully turned on ${item.title} banner visibility`,
            'success'
          )
          item.status = true
        } else {
          this.$swal(
            'Success!',
            `You have successfully turned off ${item.title} banner visibility`,
            'success'
          )
          item.status = false
        }
      } catch (error) {
        if (value) {
          this.$toastr.e(error.message, 'Error')
          throw new Error('Banner Turn On Error')
        } else {
          this.$toastr.e(error.message, 'Error')
          throw new Error('Banner Turn Off Error')
        }
      }
    },
    handleBannerDiscoveryLinking () {
      this.$bvModal.show('bannerDiscoveryLinkingModal')
    }
  },
  computed: {
    isValid () {
      return !!(!!this.selectedRow.action_value?.length === true ||
        !!this.selectedCarousel === true ||
        !!this.selectedRoute === true)
    }
  }
}
</script>

<style scoped>
.ctaUrlParent {
  position: relative;
}

.ctaUrlChild {
  position: absolute;
  top: 42px;
  right: 10px;
  color: gray;
  font-size: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
